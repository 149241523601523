import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Tag from "../components/tag"
import { rhythm, scale } from "../utils/typography"

// const FeatureImage = styled.div`
//   height: 250px;
//   background-position: center left;
//   background-repeat: no-repeat;
//   background-size: cover;
//   background-image: url(${props => props.url});
//   margin: 2% auto;
//   @media only screen and (max-width: 767px) {
//     background-position: top left;
//   }
// `

const ImgWrapper = styled.div`
  margin: 2% auto;
  img {
    width: 100%;
    height: auto;
  }
`

const TagsWrap = styled.div`
  margin-bottom: 5%;
  display: block;
`
const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const { previous, next } = pageContext

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article itemScope itemType="http://schema.org/Article">
        <header>
          <h1
            itemProp="headline"
            style={{
              marginTop: rhythm(2),
              marginBottom: 0,
            }}
          >
            {post.frontmatter.title}
          </h1>
          {post.frontmatter.thumbnail && (
            <ImgWrapper>
              <Img
                fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                alt={`${data.site.siteMetadata?.title} article hero image`}
              />
            </ImgWrapper>
          )}
          <p
            style={{
              ...scale(-1 / 5),
              display: `block`,
              marginBottom: rhythm(0.3),
            }}
          >
            {post.frontmatter.date}
          </p>
          <TagsWrap>
            {post.frontmatter.tags.map((tag, key) => (
              <Tag key={key} name={tag} />
            ))}
          </TagsWrap>
        </header>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 630, maxHeight: 250, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`
