import React from "react"
import styled from "styled-components"

const Tag = styled.div`
  background-color: #007acc;
  color: #ffffff;
  display: inline;
  margin-right: 10px;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 0.7rem;
`

export default tag => {
  return (
    <>
      <Tag>{tag.name}</Tag>
    </>
  )
}
